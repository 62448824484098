<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Roles</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">Administrar Roles <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge></div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Rol Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nuevo Rol'"
          @click="openNuevo"
        ></Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtroles"
        :value="roles"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Roles"
        responsiveLayout="scroll"
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          Se estan buscando Roles, por favor espere!
        </template>

        <template #empty> No existen Roles </template>

        <Column field="id" header="CÓDIGO" :sortable="true">
          {{ data.id }}
        </Column>
        <Column field="name" header="NOMBRE ROL">
          {{ data.name }}
        </Column>
        <Column field="created_at" header="FECHA CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="updated_at" header="ÚLTIMA MODIFICACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.updated_at) }}
          </template>
          |
        </Column>
        <Column headerStyle="min-width:10rem;" header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="rolDialog"
      :style="{ width: '450px' }"
      header="Rol Detalle"
      :modal="true"
      class="p-fluid"
    >
      <div class="field col-12">
        <label for="name"
          ><strong>Nombre de Rol: </strong> <span class="p-invalid">*</span></label
        >
        <InputText
          id="name"
          v-model.trim="rol.name"
          required="true"
          autofocus
        />
        <small class="p-invalid" v-if="errors.name">{{ errors.name[0] }}</small>
      </div>
      <!-- <div class="card">
        <permission-form :editar-componente="true"></permission-form>
      </div> -->
      <template #footer>
        <label class="flex flex-column md:flex-row"
          ><strong>Campos Requeridos: </strong> <span class="p-invalid">*</span></label
        >
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-secondary p-button-lg"
          @click="ocultarDialog"
          :disabled="!enviado ? false : true"
        />
        <Button
          label="Guardar"
          icon="pi pi-check"
          class="p-button-primary p-button-lg"
          @click="guardarRol"
          :disabled="!enviado ? false : true"
          :loading="enviado"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteRolDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="rol"
          >Estas Seguro que quieres eliminar la rol<b> {{ rol.name }}</b
          > ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="deleteRolDialog = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-prymary"
          @click="deleteRol"
        />
      </template>
    </Dialog>
    <PermissionCreateVue
      :rol="rol"
      :show="activarModalPermisos"
      @closeModal="closeModal"
      @actualizarRoles="actualizarRoles"
    ></PermissionCreateVue>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import RolService from "@/service/RolService";
import PermissionCreateVue from "@/module/roles/PermissionCreate.vue";
import { useAuth } from "@/stores";
/*
import PermissionsForm from "@/module/roles/PermissionsForm.vue"; */

export default {
  components: {
    PermissionCreateVue /* ,
    'permission-form' : PermissionsForm  */,
  },
  data() {
    return {
      activarModalPermisos: false,
      roles: null,
      cargando: true,
      rolDialog: false,
      deleteRolDialog: false,
      rol: {},
      buscar: {},
      enviado: false,
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
    };
  },
  rolService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.rolService = new RolService();
    this.initBuscar();
  },
  mounted() {
    this.obtenerRoles();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Rol Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editRol(datos);
          },
        },
        {
          label: "Editar Permisos de Rol",
          disabled:
            "Rol Asignar Permisos" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-key",
          command: () => {
            this.addPermissions(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "Rol Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteRol(datos);
          },
        },
      ];
    },
    obtenerRoles() {
      this.rolService.getRolesAll().then((data) => {
        this.roles = data;
        this.cargando = false;
        this.roles.forEach((rol) => {
          rol.created_at = new Date(rol.created_at);
          rol.updated_at = new Date(rol.updated_at);
        });
      });
    },
    actualizarRoles() {
      this.obtenerRoles();
    },
    closeModal() {
      this.errors = {};
      this.activarModalPermisos = false;
    },
    addPermissions(rol) {
      this.rol = rol;
      this.activarModalPermisos = true;
    },
    openNuevo() {
      this.enviado = false;
      this.rolDialog = true;
    },
    ocultarDialog() {
      this.rolDialog = false;
      this.enviado = false;
    },
    guardarRol() {
      this.errors = {};
      this.enviado = true;
      if (this.rol.id) {
        //EDITANDO SUCURSAL
        let rol_enviar = {
          ...this.rol,
        };
        this.rolService.updatedRol(rol_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};

            let aux = { ...data.rol };

            aux.created_at = new Date(aux.created_at);
            aux.updated_at = new Date(aux.updated_at);

            this.roles[this.findIndexById(aux.id)] = aux;
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.ocultarModalRol();
          }
          this.enviado = false;
        });
      } else {
        //Creando Nueva Rol

        this.rolService.sendRolNuevo(this.rol).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};

            data.rol.created_at = new Date(data.rol.created_at);
            data.rol.updated_at = new Date(data.rol.updated_at);

            this.roles.push(data.rol);

            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Rol Creado",
              life: 10000,
            });
            this.ocultarModalRol();
          }
          this.enviado = false;
        });
      }
    },
    ocultarModalRol() {
      this.errors = {};
      this.rolDialog = false;
      this.rol = {};
    },
    editRol(rol) {
      this.errors = {};
      this.rol = { ...rol };
      this.rolDialog = true;
    },
    confirmDeleteRol(rol) {
      this.rol = { ...rol };
      this.deleteRolDialog = true;
    },
    deleteRol() {
      this.rolService.deleteRol(this.rol).then((data) => {
        if(data.status == 200){
          this.$toast.add({
            severity: "success",
            summary: "Exito!",
            detail: "Rol Eliminado",
            life: 10000,
          });
          this.roles = this.roles.filter((val) => val.id !== this.rol.id);
          this.rol = {};
          this.deleteRolDialog = false;
        }else{
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: data.mensaje,
            life: 10000,
          });
          this.deleteRolDialog = false;
          this.rol = {};
        }
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
  watch: {
    roles() {
      this.totalRegistros = this.roles.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>